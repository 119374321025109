.navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1.88rem 1.25rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: -webkit-fill-available;


  &__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
  }

  &__logo {
    width: 2.375rem;
    height: 2.375rem;
    cursor: pointer;
  }

  &__menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0.54rem 0.375rem 0.47438rem 0.375rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    border: 2px solid #000;
    background: rgba(255, 255, 255, 0.00);
    -webkit-box-shadow: 3px 3px 0px 0px #000;
    box-shadow: 3px 3px 0px 0px #000;
    cursor: pointer;
  }
}