.page {
  min-height: 100vh;
  background-color: #FFEDD1;
  margin: 0;


  &__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1.88rem;

    &__success {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      &__title {
        color: #000;
        font-size: 2rem;
        font-family: "Poppins";
        margin-bottom: 1.88rem;
        text-align: center;
      }

      &__subtitle {
        color: #000;
        font-size: 1.2rem;
        font-family: "Poppins";
        margin-bottom: 1.88rem;
        text-align: center;
      }

      &__image {
        width: 80%;
        max-width: 20rem;
        margin-bottom: 1.88rem;
      }
    }
  }
}