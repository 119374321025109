.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 1.25rem;
  max-width: 768px;
}

.rabbits__container {
  // grid 2 items per row

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.88rem;
  margin: 0 auto;

  @media (max-width: 768px) {
    // grid 1 item per row
    grid-template-columns: repeat(1, 1fr);

  }

}

.title__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 3.12rem;
}


.title {
  color: #000;
  font-size: 2rem;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 2.375rem;
  margin-bottom: 1.88rem;
}

.subtitle {
  color: #000;
  font-size: 1.125rem;
  line-height: 1.8125rem;
  font-family: "Poppins";
}


.rabbit__card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1.88rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 40px;
  border: 2px solid #000;
  background: #FFF;
  -webkit-box-shadow: 3px 3px 0px 0px #000;
  box-shadow: 3px 3px 0px 0px #000;

  &:hover {
    -webkit-box-shadow: 5px 5px 0px 0px #000;
    box-shadow: 5px 5px 0px 0px #000;
    background-color: #FF9A51;

    // .rabbit__card__info__name,
    // .rabbit__card__info__work,
    // .rabbit__card__description__text {
    //   color: #FFF;
    // }
  }

  &__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }

  &__button {
    background: transparent;
    border-radius: 50%;
    width: 2.625rem;
    height: 2.625rem;
    border: 1px solid #000;
    color: black;
    font-size: 1rem;
    font-family: "Poppins";
    line-height: 1.5rem;
    padding: 0.5rem;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: #FFF;
      color: #000;
      border: 3px solid #000;
    }
  }

  &__image {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    border: 2px solid #000;
  }

  &__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 1rem;
    justify-items: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &__name {
      color: #000;
      font-size: 1.125rem;
      font-family: "Poppins";
      line-height: 1.44rem;
      margin: 0;
    }

    &__work {
      color: #000;
      font-size: 1.125rem;
      font-family: "Poppins";
      line-height: 1.44rem;
      opacity: 0.5;
      margin: 0;
    }
  }

  &__description {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-item-align: stretch;
    align-self: stretch;

    &__text {
      color: #000;
      font-size: 1rem;
      font-family: "Poppins";
      line-height: 1.5rem;
    }
  }
}