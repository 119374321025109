.subscription__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 1.25rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;

  &__title {
    color: #000;
    font-size: 2.625rem;
    font-family: Poppins;
    font-weight: 700;
    line-height: 3.5rem;
  }
}

.subscription__container__wrapper {

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.875rem;
  margin: 0 auto;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.subscription__card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1.25rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 15px;
  margin-bottom: 1.88rem;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  max-width: 328px;

  &:hover {
    // material elevation 5
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }

  &__title {
    color: #1F1F1F;
    font-size: 3.125rem;
    font-family: Poppins;
    font-weight: 700;
    line-height: 4.125rem;
    margin: 0;
    margin-bottom: 1.88rem;
  }

  &__type {
    color: #1F1F1F;
    font-size: 1.0625rem;
    font-family: Poppins;
    font-weight: 500;
    line-height: 1.25rem;
    margin: 0;

  }

  &__price {
    color: #1F1F1F;
    font-size: 1.5625rem;
    font-family: Poppins;
    font-weight: 700;
    line-height: 1.75rem;
    margin: 0;
    margin-bottom: 1.12rem;
  }

  &__features {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 1.25rem;

    &__feature {
      color: #1F1F1F;
      font-size: 0.875rem;
      font-family: Poppins;
      font-weight: 500;
      line-height: 1.0625rem;
      letter-spacing: 0.00088rem;
      margin-bottom: 1.12rem;
    }
  }
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 1.25rem;
  width: 100%;
  max-width: 768px;
}

.rabbits__container {
  // grid 2 items per row

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  margin: 0 auto;

  @media (max-width: 768px) {
    // grid 1 item per row
    grid-template-columns: repeat(1, 1fr);

  }

}

.title__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 3.12rem;
}


.title {
  color: #000;
  font-size: 2rem;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 2.375rem;
  margin-bottom: 1.88rem;
}

.subtitle {
  color: #000;
  font-size: 1.125rem;
  line-height: 1.8125rem;
  font-family: "Poppins";
}