.form__group {

  display: flex;
  flex-direction: column;
  // gap: 1rem;
  width: 100%;
  margin-bottom: 0.75rem;
  height: 70px;
}


.input__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.625rem 1.25rem;
  gap: 0.625rem;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-radius: 10px;
  background: #F4F4F4;
  border: none;
  justify-items: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.input {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 14px;
  background: transparent;
  border: none;
  outline: none;
  padding: 0.5rem;
  color: #959595;
  font-size: 1rem;
  font-family: "Poppins";
  line-height: 1.44rem;
  

  &__prefix {
    color: #959595;
    font-size: 1rem;
    font-family: "Poppins";
    line-height: 1.44rem;
  }

  &::-webkit-input-placeholder {
    color: #959595;
    font-size: 1rem;
    font-family: "Poppins";
    line-height: 1.44rem;
  }

  &::-moz-placeholder {
    color: #959595;
    font-size: 1rem;
    font-family: "Poppins";
    line-height: 1.44rem;
  }

  &:-ms-input-placeholder {
    color: #959595;
    font-size: 1rem;
    font-family: "Poppins";
    line-height: 1.44rem;
  }

  &::-ms-input-placeholder {
    color: #959595;
    font-size: 1rem;
    font-family: "Poppins";
    line-height: 1.44rem;
  }

  &::placeholder {
    color: #959595;
    font-size: 1rem;
    font-family: "Poppins";
    line-height: 1.44rem;
  }
}

.error {
  color: red;
  font-size: 0.75rem;
  font-family: Poppins;
  font-weight: 400;
}

.fullWidth {
  width: 100%;
}