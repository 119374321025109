.primary__button {
  border-radius: 5px;
  background: #3E8DFF;
  -webkit-box-shadow: 3px 3px 0px 0px #000;
  box-shadow: 3px 3px 0px 0px #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 35px;
  gap: 10px;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  text-align: center;
  color: white;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #FFF;
  font-size: 13px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 21px;
  cursor: pointer;

  &__fullWidth {
    width: 100%;
  }

  &__disabled {
    background: #BDBDBD;
    cursor: not-allowed;
  }
}