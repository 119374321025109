.page {
  max-width: 100%;
  min-height: 100vh;
  background-color: #FFEDD1;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;


  &__container {
    padding: 1.88rem;

    &__title {
      color: #000;
      font-size: 2rem;
      font-family: "Poppins";
    }

    &__faq {
      margin-top: 1.88rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      &__question {
        color: #000;
        font-size: 1.0rem;
        font-family: "Poppins";
        font-weight: 500;
        margin-bottom: 0.94rem;
      }

      &__answer {
        color: #000;
        font-size: 1rem;
        font-family: "Poppins";
        margin-bottom: 0.94rem;
      }
    }
  }
}