.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  opacity: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;


  &__close {
    background-color: transparent;
    color: #000;
    padding: 0.5rem;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.0rem;
    font-weight: 700;
    border-radius: 4px;
    border: 2px solid #000;
    background: rgba(255, 255, 255, 0.00);
    -webkit-box-shadow: 3px 3px 0px 0px #000;
    box-shadow: 3px 3px 0px 0px #000;
    cursor: pointer;
  }

  &__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 1.5rem;
    max-height: 90%;

  }

  &__body {
    padding: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    &__image {
      width: 50%;
      -o-object-fit: contain;
      object-fit: contain;
    }
  }
}

.open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 1;
}