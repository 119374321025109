.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 4.6875rem 0rem;
  gap: 5.625rem;
  background: #FF9A51;
  width: 100%;

  &__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1.25rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  &__title {
    color: #000;
    text-align: center;
    font-size: 1rem;
    font-family: Poppins;
    font-weight: 700;
    line-height: 1.5rem;
  }

  &__description {
    color: #000;
    text-align: center;
    font-size: 0.875rem;
    font-family: Poppins;
    font-weight: 500;
    line-height: 1.375rem;
  }
}