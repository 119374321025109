.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  display: none;
  opacity: 0;

  // soft transition from top to bottom


  &__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &__close {
      background-color: transparent;
      color: #000;
      padding: 0.5rem;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1.0rem;
      font-weight: 700;
      border-radius: 4px;
      border: 2px solid #000;
      background: rgba(255, 255, 255, 0.00);
      -webkit-box-shadow: 3px 3px 0px 0px #000;
      box-shadow: 3px 3px 0px 0px #000;
      cursor: pointer;
    }
  }

  &__content {
    background-color: #FF9A51;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;
  }

  &__body {
    padding: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    height: 80%;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    &__item {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      margin-bottom: 1rem;


      &:hover {
        color: #000;
        background-color: #fff;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:visited {
        color: #000;
      }

      &__link {
        border: none;
        background-color: transparent;
        color: #000;
        font-family: 'Poppins';
        text-decoration: none;
        font-size: 2.4rem;
        font-weight: 700;
        letter-spacing: -1.58px;
        line-height: 52.8px;
        text-align: center;
        cursor: pointer;
      }
    }


  }
}

.open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}



.modal__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 1rem;


  &__close {
    background-color: transparent;
    color: #000;
    padding: 0.5rem;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.0rem;
    font-weight: 700;
    border-radius: 4px;
    border: 2px solid #000;
    background: rgba(255, 255, 255, 0.00);
    -webkit-box-shadow: 3px 3px 0px 0px #000;
    box-shadow: 3px 3px 0px 0px #000;
    cursor: pointer;
  }
}

.modal__container {
  position: relative;
  padding: 1rem;
  border-radius: 0.5rem;
  overflow: auto;
  max-width: 768px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  &__text {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    line-height: 40.3px;
    margin-top: 1rem;
    text-align: center;
  }
}