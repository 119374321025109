.page {
  width: 100vw;
  background-color: #FFEDD1;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.image__container {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}

.info__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  padding: 4.6875rem 1.25rem;
  gap: 2.125rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.info__body {
  max-width: 768px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}


.info__title {
  color: #000;
  font-size: 2rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  line-height: 2.875rem;
}

.info__text {
  color: #000;
  font-size: 1.125rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 1.8125rem;
}


.blue__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  padding: 4.6875rem 1.25rem;
  gap: 2.125rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 0;
  background: #63A3FF;

  &__title {
    color: #000;
    font-size: 2.625rem;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    line-height: 3.5rem;
    margin-bottom: 2.12rem;
  }

  &__body {
    max-width: 768px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  &__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-bottom: 3.12rem;
    padding: 0;


    &__title {
      color: #000;
      font-size: 1.0rem;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      line-height: 2.125rem;
      margin: 0;
    }

    &__text {
      color: #000;
      font-size: 1.0rem;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      line-height: 1.8125rem;
      margin: 0;
    }
  }

  &__image__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;

    &__image {
      width: 100vw;
      max-width: 600px;
      height: auto;
    }
  }
}

.container__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.image {
  margin-top: 7.5rem;
  width: 100vw;
  max-width: 600px;
  height: auto;
}

.image__love {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
  width: 100vw;
  max-width: 600px;
  height: auto;
}

.image__click {
  margin-top: 7.5rem;
  width: 100vw;
  max-width: 600px;
  height: auto;

  @media (min-width: 768px) {
    display: none;
  }
}